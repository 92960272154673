import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import StringProcess from '../../components/design/StringProcess';

// import { TagElement } from '../../components/Elements';

const Content = {
    title: 'Our %Story%',
    subtitle: 'Azure // AWS // Google Cloud // IoT',
    text: {
        value: "We're an %AI% powered start-up located in **Toronto** at the heart of one of the fastest growing __superclusters__ in the world. Founded 7 years ago, we've built **enterprise-class** solutions. Get in touch to enable new revenue streams, increase efficiency of your cloud transformation and deliver the future with leading advisory services and industry experts by your side. Scale with %Quant ONE%.",
        accent: 'innovators // cloud architects // strategic thinkers // designers',
    },
};

class OurStory extends React.Component {
    public render() {
        return (
            <Flex flexWrap={'nowrap'} py={[3, 5]}>
                <Box width={[7 / 10]}>
                    <Box>
                        <Text fontSize={['xl']} color={'gray.700'}>
                            {Content.subtitle}
                        </Text>
                    </Box>
                    <Text fontSize={['4xl', '6xl']} color={'white'} ml={'-5px'}>
                        <StringProcess>{Content.title}</StringProcess>
                    </Text>
                    <Box my={[5, 7]}>
                        <LargeText>
                            <StringProcess>{Content.text.value}</StringProcess>
                        </LargeText>
                    </Box>
                </Box>
                <Box width={[1 / 10]}>
                    <VerticalText>{Content.text.accent}</VerticalText>
                </Box>
            </Flex>
        );
    }
}

export default OurStory;

const Title = styled(Text)``;

const LargeText = styled(Text)`
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.5;
    text-transform: uppercase;
    color: ${p => p.theme.colors.brand.white};
`;

const VerticalText = styled(Text)`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 66px;
    text-align: right;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: ${p => p.theme.colors.brand.greys.grey70};

    display: flex;
    align-items: flex-end;
    transform: rotate(-90deg);
    width: max-content;
`;

import * as React from "react";
import AppContainer from "../components/Layout/AppContainer";
import Layout from "../components/Layout/Layout";
import CoverHeading from "../components/cover/CoverHeading";

import AboutBlock from "../static/pages/AboutBlock";
import SocialConversion from "../components/cta/SocialConversion";
import Mission from "../content/containers/Mission";
import Section from "../components/Layout/Section";
import OurStory from "../content/containers/OurStory";

class About extends React.Component {
    private CoverContent() {
        const { id, slug, header } = AboutBlock;

        return (
            <CoverHeading id={id} slug={slug} header={header} containers={[]} />
        );
    }

    public render() {
        return (
            <AppContainer>
                <Layout cover={<this.CoverContent />}>
                    <Section>
                        <Mission />
                        <OurStory />
                    </Section>
                    <SocialConversion />
                </Layout>
            </AppContainer>
        );
    }
}

export default About;
